<template>
    <div>
        <b-row>
            <b-col>
                <b-card :header="$t('meetings')" class="soft mb-5">
                <b-table :empty-filtered-text="$t('no_result')"
                         :empty-text="$t('no_result')"
                         bordered
                         responsive
                         :items="mappedContact"
                         :fields="meetingsFields"
                         show-empty
                         class="mb-4 table-dropdown no-scrollbar border rounded">
                    <template #cell(buttons)="row">
                        <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <div id="dropdownListHead">
                                <b-dropdown-item @click="$emit('updateClick',['meetings','edit'],row.item.id)" v-if="checkPermission('documentrequest_sendebys')">
                                    <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteMeeting(row.item.id)" v-if="checkPermission('documentrequest_sendebys')">
                                    <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                    </template>
                </b-table>
                <b-button variant="primary" class="mr-2" @click="$emit('updateClick',['meetings','add'])">
                    <i class="ri-add-line mr-1"></i> {{$t('add')}}
                </b-button>
            </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniContactService from '@/services/AlumniContactService';
import AlumniService from '@/services/AlumniService';

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {

    },

    data() {
        return {
            responseContactData: [],
            meetingsFields: [
                {
                    key: 'buttons',
                    label: '',
                },
                {
                    key: 'date',
                    label: this.$t('registration_date_just'),
                },
                {
                    key: 'note',
                    label: this.$t('meeting_note'),
                },
                {
                    key: 'status',
                    label: this.$t('meeting_status'),
                }
            ]
        }
    },
    watch: {
        isUpdate: {
            handler(value){
                this.ContactData()
            }
        }
    },
    created() {
        this.studentId = this.$route.params.id
        this.ContactData()
    },
    computed: {
        mappedContact() {
            return this.responseContactData.map(item => {
                let translatedContact = '';
                switch (item.status) {
                    case 'did_not_answer':
                        translatedContact = this.$t('did_not_answer')
                        break;
                    default:
                        translatedContact = item.status;
                }
                return {
                    ...item,
                    status: translatedContact
                }
            });
        }
    },
    methods: {
        ContactData(){
            return AlumniService.ContactInfo(this.$route.params.id)
                .then(response => {
                    this.responseContactData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        deleteMeeting(id) {
            this.deleteModal(() => {
                AlumniContactService.del(id)
                    .then(response => {
                        this.ContactData()
                        this.$emit('updateSuccess','meetings')
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
    }
}
</script>

