<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('other')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_card_status') }}</label>
                                <div v-if="responseOtherData && responseOtherData.alumni_card_status && (responseOtherData.alumni_card_status === 'card_created')">{{ $t('card_created') }}</div>
                                <div v-else-if="responseOtherData && responseOtherData.alumni_card_status && (responseOtherData.alumni_card_status === 'card_not_created')">{{ $t('card_not_created') }}</div>
                                <div v-else>{{ responseOtherData.alumni_card_status }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_email_status') }}</label>
                                <div>{{ responseOtherData.alumni_email_status }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('death_status') }}</label>
                                <div v-if="responseOtherData && responseOtherData.death_status && (responseOtherData.death_status === 'in_life')">{{ $t('in_life') }}</div>
                                <div v-else-if="responseOtherData && responseOtherData.death_status && (responseOtherData.death_status === 'died')">{{ $t('died') }}</div>
                                <div v-else>{{ responseOtherData.death_status }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('kvkk_code') }}</label>
                                <div>{{ responseOtherData.kvkk_code }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('last_updated') }}</label>
                                <div>{{ responseOtherData.last_updated_by }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('last_update_datetime') }}</label>
                                <div>{{ responseOtherData.last_updated_at }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-4" variant="primary" @click="$emit('updateClick',['other'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {

    },

    data() {
        return {
            responseOtherData: {
                alumni_card_status: '',
                alumni_email_status: '',
                death_status: '',
                kvkk_code: '',
                last_updated_by: '',
                last_updated_at: ''
            }
        }
    },
    created() {
        this.OtherData()
    },
    watch: {
        isUpdate: {
            handler(value){
                this.OtherData()
            }
        }
    },
    methods: {
        OtherData(){
            return AlumniService.OtherInfo(this.$route.params.id)
                .then(response => {
                    this.responseOtherData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },

    }
}
</script>

