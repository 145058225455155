<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div v-show="formProcess=='smsVerify'">
                <div class="mb-3">
                    <ValidationObserver ref="formSmsVerify">
                        <ValidationProvider name="sms_code" rules="required" v-slot="{valid, errors}">
                            <sms-input v-model="smsCode"
                                       :timerFrom="smsTimer"
                                       @timerExpired="timerExpired"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <b-row>
                    <b-col>
                        <b-button type="button"
                                  variant="primary"
                                  size="lg"
                                  block
                                  @click="smsVerify"
                                  :disabled="smsTimer==0"
                        >
                            {{ $t('verify').toUpper() }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-button type="submit"
                                  variant="outline-secondary"
                                  size="lg"
                                  block
                                  @click="smsSend()"
                                  :disabled="smsTimer>0"
                        >
                            {{ $t('send_again').toUpper() }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import Loading from '@/components/elements/Loading.vue';
import SmsInput from '@/components/elements/SmsInput.vue';

// Services
import AlumniService from "@/services/AlumniService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import { EventBus } from "@/main"


export default {
    components: {
        Loading,
        SmsInput,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        idStudent: {
            type: Number,
        }
    },
    data() {
        return {
            // Page
            pageLoading: false,
            formLoading: false,
            formProcess: 'smsVerify',
            smsTimer: 60,
            smsCode: '',
            id: null
        }
    },
    async created() {
        this.id = this.idStudent;
    },
    methods: {
        timerExpired(event) {
            this.smsTimer = 0;
        },
        async smsSend() {
            let formData = {};
            AlumniService.sendSmsCode(this.id, formData)
                .then(response => {
                    let data = response.data.data;
                    this.smsTimer = data.expires_in;
                })
                .catch(e => {
                    this.showErrors(e);
                });
        },
        async smsVerify() {
            const isValid = await this.$refs.formSmsVerify.validate();
            if (isValid) {
                let formData = {
                    code: this.smsCode
                };
                this.formLoading = true;
                AlumniService.verifySmsCode(this.id, formData)
                    .then(response => {
                        let message = response.data.message;
                        this.$toast.success(this.$t('api.' + message));
                        this.$emit('mobileNumberUpdated');
                    })
                    .catch(e => {
                        this.showErrors(e);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    });
            }
        }
    }
}
</script>
