<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="3">
                    <ValidationProvider name="personal_email" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('personal_email')">
                            <b-form-input v-model="responsePersonalData.personal_email"
                                          :placeholder="$t('personal_email')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="alumni_email" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('graduate_email')">
                            <b-form-input v-model="responsePersonalData.alumni_email"
                                          :placeholder="$t('graduate_email')"
                                          :state="errors[0] ? false : null"
                                          disabled />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="phone" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('mobile_number')">
                            <b-form-input v-model="responsePersonalData.phone"
                                          :placeholder="$t('mobile_number')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="marital_status" rules="" v-slot="{errors}">
                        <b-form-group :label="$t('marriage_status')">
                            <multi-selectbox v-model="responsePersonalData.marital_status"
                                             :multiple="false"
                                             :options="marriageStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="child_status" rules="" v-slot="{errors}">
                        <b-form-group :label="$t('children_available')">
                            <multi-selectbox v-model="responsePersonalData.child_status"
                                             :multiple="false"
                                             :options="childrenAvailableOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="country" rules="" v-slot="{errors}">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="responsePersonalData.country"
                                               valueType="code"
                                             :validate-error="errors[0]">
                            </country-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                <b-row class="mb-4">
                        <b-col cols="12" md="6" class="mb">
                            <b-card  :header="$t('residence_address')" class="soft h-100">
                                <ValidationProvider name="city" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('current_city')">
                                        <b-form-input v-model="responsePersonalData.city"
                                            :validate-error="errors[0]"
                                            :disabled="true">
                                         </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                                <b-row>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="district" rules="" v-slot="{errors}">
                                            <b-form-group :label="$t('current_district')">
                                                <b-form-input v-model="responsePersonalData.district"
                                                        :validate-error="errors[0]"
                                                        :disabled="true">
                                                </b-form-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="address" rules="" v-slot="{ errors }">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input v-model="responsePersonalData.address"
                                                        :placeholder="$t('address')"
                                                        :state="errors[0] ? false : null"
                                                        readonly/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <b-col cols="12" lg="12">
                                    <b-button variant="primary" @click="addressUpdate" v-if="checkPermission('student_updatestudentaddressinformationfromkps')">
                                            {{ $t('mernis_edit') }}
                                    </b-button>
                                </b-col>
                            </b-card>
                        </b-col>
                        <b-col cols="12" md="6" class="mb">
                            <b-card :header="$t('communication_address_2')" class="soft h-100">
                                <b-row>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="contact_city_id" rules="" v-slot="{ errors }">
                                            <b-form-group :label="$t('communication_city')">
                                                <city-selectbox v-model="responsePersonalData.contact_city_id"
                                                        :state="errors[0] ? false : null">
                                                </city-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="contact_district_id" rules="" v-slot="{ errors }">
                                            <b-form-group :label="$t('communication_district')">
                                                <district-outline-selectbox
                                                    v-model="responsePersonalData.contact_district_id"
                                                    :city_id="responsePersonalData.contact_city_id"
                                                    :state="errors[0] ? false : null">
                                                </district-outline-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" sm="12">
                                        <ValidationProvider name="contact_address" rules="" v-slot="{ errors }">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input v-model="responsePersonalData.contact_address"
                                                        :state="errors[0] ? false : null"/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox"
import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
import StudentService from "@/services/StudentService";
//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,

        MultiSelectbox,
        CountrySelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox
    },
    data() {
        return {
            responsePersonalData:{
                personal_email: null,
                alumni_email: null,
                phone: null,
                marital_status: null,
                child_status: null,
                country: null,
                city: null,
                district: null,
                address: null,
                contact_city_id: null,
                contact_district_id: null,
                contact_address: null
            },
            studentId: null,
            alumniId: null,
            marriageStatusOptions: [
                {
                    text: 'Evli',
                    value: 'Evli'
                },
                {
                    text: 'Bekar',
                    value: 'Bekar'
                }
            ],
            childrenAvailableOptions: [
                {
                    text: this.$t('available'),
                    value: 'Var'
                },
                {
                    text: this.$t('none'),
                    value: 'Yok'
                }
            ],
            showLoading: false
        }
    },
    created() {
        this.setData()
        this.PersonalData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData() {
            this.responsePersonalData = {}
        },
        PersonalData(){
            return AlumniService.PersonalInfo(this.$route.params.id)
                .then(response => {
                    this.responsePersonalData  = response.data.data;

                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let responsePersonalData = {
                    ...this.responsePersonalData
                }

              await AlumniService.personalInfoUpdate(this.$route.params.id, responsePersonalData)
                .then(response => {
                    this.PersonalData()
                    this.$emit('updateSuccess','personal_info')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
        addressUpdate() {
            AlumniService.mernisAddressUpdate(this.responsePersonalData.id)
            .then(response => {
                this.$emit('updateSuccess')
            this.$toast.success(this.$t('api.' + response.data.message));
		    }).catch(e => {this.showErrors(e, this.$refs.formModalValidate)
            }).finally(() => {	this.formProcess = false})
        }

    }
}
</script>

