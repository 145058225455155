<template>
    <div>
        <b-row>
            <b-col>
                <b-card :header="$t('work_history')" class="soft mb-5">
                <b-table :empty-filtered-text="$t('no_result')"
                         :empty-text="$t('no_result')"
                         bordered
                         responsive
                         :items="mappedWorkHistory"
                         :fields="workingHistoriesFields"
                         show-empty
                         class="mb-4 table-dropdown no-scrollbar border rounded">
                    <template #cell(buttons)="row">
                        <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <div id="dropdownListHead">
                                <b-dropdown-item @click="$emit('updateClick',['work_history','edit'],row.item.id)" v-if="checkPermission('documentrequest_sendebys')">
                                    <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteHistory(row.item.id)" v-if="checkPermission('documentrequest_sendebys')">
                                    <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                    </template>
                </b-table>
                <b-button variant="primary" class="mr-2" @click="$emit('updateClick',['work_history','add'])">
                    <i class="ri-add-line mr-1"></i> Ekle
                </b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniWorkService from '@/services/AlumniWorkService';
import AlumniService from '@/services/AlumniService';

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {

    },

    data() {
        return {
            responseWorkHistoryData: [],
            workingHistoriesFields: [
                {
                    key: 'buttons',
                    label: '',
                },
                {
                    key: 'start_date',
                    label: this.$t('work_start_date'),
                },
                {
                    key: 'end_date',
                    label: this.$t('work_leave_date'),
                },
                {
                    key: 'company',
                    label: this.$t('corporation'),
                },
                {
                    key: 'department',
                    label: this.$t('corp_department'),
                },
                {
                    key: 'contract_type',
                    label: this.$t('working_type'),
                },
                {
                    key: 'workspace_status',
                    label: this.$t('working_tract'),
                },
            ]
        }
    },
    watch: {
        isUpdate: {
            handler(value){
                this.WorkHistoryData()
            }
        }
    },
    created() {
        this.WorkHistoryData()
    },
    computed: {
        mappedWorkHistory() {
            return this.responseWorkHistoryData.map(item => {
                let translatedContractType = '';
                switch (item.contract_type) {
                    case 'full_time':
                        translatedContractType = this.$t('full_time')
                        break;
                    default:
                        translatedContractType = item.contract_type;
                }
                return {
                    ...item,
                    contract_type: translatedContractType
                }
            });
        }
    },
    methods: {
        WorkHistoryData(){
            return AlumniService.WorkHistoryInfo(this.$route.params.id)
                .then(response => {
                    this.responseWorkHistoryData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        deleteHistory(id){
            this.deleteModal(() => {
                AlumniWorkService.del(id)
                    .then(response => {
                        this.WorkHistoryData()
                        this.$emit('updateSuccess','work_history')
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        }
    }
}
</script>

