import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/list', config);
}

const get = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/programs/' + studentId);
}

const PersonalInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/personal-information/' + studentId);
}

const WorkingInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/working-information/' + studentId);
}

const EducationInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/educations/' + studentId);
}

const CommunicationInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/communication-approve-information/' + studentId);
}

const OtherInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/other-information/' + studentId);
}

const WorkHistoryInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/works/' + studentId);
}

const ContactInfo = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/contacts/' + studentId);
}

const mernisAddressUpdate = async (alumniId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/update-address-from-mernis/' + alumniId);
}

const degreeInformation = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/want-degree-information/' + studentId);
}

const contactsInformation = async (studentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/contacts/' + studentId);
}

const personalInfoUpdate = async (studentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/alumni/personal-information/' + studentId, formData);
}

const workingUpdate = async (studentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/alumni/working-information/' + studentId, formData);
}

const communicationInfoUpdate = async (studentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/alumni/communication-approve-information/' + studentId, formData);
}

const otherInfoUpdate = async (studentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/alumni/other-information/' + studentId, formData);
}

const degreeInformationUpdate = async (studentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/alumni/want-degree-information/' + studentId, formData);
}

const verifySmsCode = async (alumniId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/alumni/verify-kvkk-code/' + alumniId, formData);
}

const sendSmsCode = async (alumniId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/send-kvkk-code/' + alumniId);
}

export default {
    getAll,
    get,
    PersonalInfo,
    WorkingInfo,
    EducationInfo,
    CommunicationInfo,
    OtherInfo,
    WorkHistoryInfo,
    ContactInfo,
    mernisAddressUpdate,
    degreeInformation,
    contactsInformation,
    personalInfoUpdate,
    workingUpdate,
    communicationInfoUpdate,
    otherInfoUpdate,
    degreeInformationUpdate,
    verifySmsCode,
    sendSmsCode
}
