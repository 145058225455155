<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="3">
                    <ValidationProvider name="alumni_card_status" rules="" v-slot="{errors}">
                        <b-form-group :label="$t('graduate_card_status')">
                            <multi-selectbox
                                :multiple="false"
                                :options="cardStatusOptions"
                                v-model="formData.alumni_card_status"
                                disabled
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="alumni_email_status" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('graduate_email_status')">
                            <b-form-input v-model="formData.alumni_email_status"
                                          :placeholder="$t('graduate_email_status')"
                                          disabled
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="death_status" rules="" v-slot="{errors}">
                        <b-form-group :label="$t('death_status')">
                            <multi-selectbox
                                :multiple="false"
                                :options="deathOptions"
                                v-model="formData.death_status" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="kvkk_code" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('kvkk_code')">
                            <b-form-input v-model="formData.kvkk_code"
                                          :placeholder="$t('kvkk_code')"
                                          :state="errors[0] ? false : null"
                                          disabled />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button class="mr-2" variant="outline-primary" @click="sendCode()">{{ $t('kvkk_code_send') }}</b-button>
                    <b-button class="mr-2" variant="outline-primary">{{ $t('activate_graduate_email') }}</b-button>
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
        <CommonModal ref="mobileNumberUpdateModal"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('edit') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <mobile-number-update v-if="formProcess=='mobile-number-update'"
                                      @mobileNumberUpdated="mobileNumberUpdateModalSuccess()"
                                      :idStudent="studentData.id"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Template

// Components

//Pages
import MobileNumberUpdate from './MobileNumberUpdate.vue';

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {EventBus} from "@/main";

export default {
    components: {
        MobileNumberUpdate,
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            formData: {
                alumni_card_status: null,
                alumni_email_status: null,
                death_status: null,
                kvkk_code: null,
            },
            cardStatusOptions: [
                {
                    text: this.$t('card_created'),
                    value: 'card_created'
                },
                {
                    text: this.$t('card_not_created'),
                    value: 'card_not_created'
                }
            ],
            deathOptions: [
                {
                    text: this.$t('in_life'),
                    value: 'in_life'
                },
                {
                    text: this.$t('died'),
                    value: 'died'
                }
            ],
            formProcess: null,
            studentData: {},
        }
    },
    created() {
        this.OtherData()
        this.PersonalData()
        this.studentId = this.$route.params.id
    },
    methods: {
        formClear() {
            this.formProcess = null
        },
        setData(){
            this.formData = {
                alumni_card_status: this.responseOtherData.alumni_card_status??null,
                alumni_email_status: this.responseOtherData.alumni_email_status,
                death_status: this.responseOtherData.death_status,
                kvkk_code: this.responseOtherData.kvkk_code
            }
        },
        OtherData(){
            return AlumniService.OtherInfo(this.$route.params.id)
                .then(response => {
                    this.responseOtherData  = response.data.data;
                    this.setData()
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        PersonalData(){
            return AlumniService.PersonalInfo(this.$route.params.id)
                .then(response => {
                    this.studentData  = response.data.data;

                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    ...this.formData
                }

                AlumniService.otherInfoUpdate(this.$route.params.id, formData)
                .then(response => {
                    this.OtherData()
                    this.$emit('updateSuccess','other')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
        async sendCode() {
            let formData = {
                mobile_tel: this.studentData.phone ? this.studentData.phone:''
            }
                AlumniService.sendSmsCode(this.studentData.id, formData)
                    .then(response => {
                        let data = response.data.data;
                        this.smsTimer = data.expires_in
                        this.formProcess = 'mobile-number-update'
                        this.$refs.mobileNumberUpdateModal.$refs.commonModal.show()
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formSmsSend);
                    })

        },
        mobileNumberUpdateModalSuccess() {
            this.formProcess = null
            this.$refs.mobileNumberUpdateModal.$refs.commonModal.hide()
            this.OtherData()
            EventBus.$emit('closeModal', 'other');
        },
    },
}


</script>

