<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_start_date')">
                            <select-date v-model="formData.start_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="end_date" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_leave_date')">
                            <select-date v-model="formData.end_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="company" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corporation')">
                            <b-form-input v-model="formData.company"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="department" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corp_department')">
                            <b-form-input v-model="formData.department"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="contract_type" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_type')">
                            <parameter-selectbox
                                code="alumni_working_style"
                                v-model="formData.contract_type"
                                :state="errors[0] ? false : null"
                            ></parameter-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="workspace_status" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_tract')">
                            <b-form-input v-model="formData.workspace_status"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="sector" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('job_sector')">
                            <parameter-selectbox
                                code="alumni_sector_of_work"
                                v-model="formData.sector"
                                :state="errors[0] ? false : null"
                            ></parameter-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="position" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('positional_status')">
                            <parameter-selectbox
                                code="alumni_position"
                                v-model="formData.position"
                                :state="errors[0] ? false : null"
                            ></parameter-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
<!--                <b-col md="4" class="mt-5">-->
<!--                    <b-row>-->
<!--                        <b-col cols="6" class="mt-2">-->
<!--                            <ValidationProvider name="still_work" rules="" v-slot="{valid, errors}">-->
<!--                                <b-form-group>-->
<!--                                    <b-form-checkbox v-model="formData.still_work" :state="errors[0] ? false : null">Hala Çalışıyor</b-form-checkbox>-->
<!--                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
<!--                                </b-form-group>-->
<!--                            </ValidationProvider>-->
<!--                        </b-col>-->
<!--                        <b-col cols="6" class="mt-2">-->
<!--                            <ValidationProvider name="position" rules="" v-slot="{valid, errors}">-->
<!--                                <b-form-group>-->
<!--                                    <b-form-checkbox v-model="formData.position" :state="errors[0] ? false : null">İş Olanağını Okul Buldu</b-form-checkbox>-->
<!--                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
<!--                                </b-form-group>-->
<!--                            </ValidationProvider>-->
<!--                        </b-col>-->
<!--                    </b-row>-->
<!--                </b-col>-->
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

//Pages

// Services
import AlumniWorkService from '@/services/AlumniWorkService';
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props:{
        formId:{
            type:Number
        },
        isUpdate: {
            type: Boolean
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        ParameterSelectbox
    },

    data() {
        return {
            formData: {
                start_date: null,
                end_date: null,
                company: null,
                department: null,
                contract_type: null,
                workspace_status: null,
                still_work: null,
                position: null,
                sector: null
            },
            studentId: null
        }
    },
    created() {
        this.setData()
        this.WorkHistoryData()
        this.studentId = this.$route.params.id
    },
    watch: {
        formId(newFormId) {
            this.setData()
            this.EducationData()
        },
        isUpdate: {
            handler(value){
                this.WorkHistoryData()
            }
        },
    },
    methods: {
        setData(){
            this.formData = {
                start_date: null,
                end_date: null,
                company: null,
                department: null,
                contract_type: null,
                workspace_status: null,
                sector: null,
                position: null,
            }
        },
        WorkHistoryData(){
            return AlumniService.WorkHistoryInfo(this.$route.params.id)
            .then(response => {
                    let data = response.data.data;
                    const selectedData = data.filter((item)=>{
                       return item.id===this.formId
                    })
                    this.formData = {
                        start_date: selectedData[0].start_date,
                        end_date: selectedData[0].end_date,
                        company: selectedData[0].company,
                        department: selectedData[0].department,
                        contract_type: selectedData[0].contract_type,
                        workspace_status: selectedData[0].workspace_status,
                        position: selectedData[0].position,
                        sector: selectedData[0].sector,
                    }
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        async saveForm() {
            const isValid = await this.$refs.formValidate.validate();
                if (isValid) {

                    let formData = {
                    ...this.formData,
                    student_id: this.studentId

                }
                AlumniWorkService.update(this.formId, formData)
                                 .then((response) => {
                                     this.formLoading=false;
                                     this.WorkHistoryData()
                                     this.$emit('updateSuccess','work_history')
                                     this.$toast.success(this.$t("api." + response.data.message));
                                 })
                                 .catch((error) => {
                                     this.showErrors(error, this.$refs.formModalValidate)
                                 })
                }
        }
    }
}
</script>

